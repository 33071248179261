import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby'
import Img from 'gatsby-image'
import ReactHtmlParser from 'react-html-parser'
import { HelmetDatoCms } from 'gatsby-source-datocms'

import Layout from '../components/layout'
import '../styles/pages/_blog_post.scss'

const datePublished = postDate => {
  const date = new Date(postDate)
  return new Intl.DateTimeFormat('en-GB', {
    weekday: 'long',
    day: '2-digit',
    month: 'long',
    year: 'numeric'
  }).format(date)
}

const BlogPostTemplate = ({ data: { datoCmsPost: post } }) => (
  <Layout>
    <HelmetDatoCms seo={post.seoMetaTags} />
    <section
      className="hero is-large is-page-hero"
      style={{
        backgroundImage: `url(${post.featuredImage.fixed.src})`
      }}
    >
      <div className="hero-body">
        <div className="container">
          <h1 className="title has-text-white has-text-weight-normal is-size-2">
            {post.title}
          </h1>
          <div className="media">
            <div className="media-left">
              <figure className="image is-48x48">
                <Img
                  fixed={post.author.profileImage.fixed}
                  alt={`${post.author.name} Profile Image`}
                />
              </figure>
            </div>
            <div className="media-content">
              <p className="title is-4 has-text-white has-text-weight-normal">
                {post.author.name}
              </p>
              <p className="subtitle is-6 has-text-white has-text-weight-light">
                {datePublished(post.publishedDate)}
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div className="container">
      <section className="hero">
        <div className="hero-body">
          <div className="container">
            <h3 className="subtitle">{post.title}</h3>
            <p>
              -{' '}
              <small>
                {post.textNode.childMarkdownRemark.timeToRead} minute read
              </small>
            </p>
            <br />
            <div className="content blog-content">
              {ReactHtmlParser(post.textNode.childMarkdownRemark.html)}
            </div>
          </div>
        </div>
      </section>
      <hr />
      <section className="section">
        <div className="column is-half">
          <article className="media">
            <div className="media-left">
              <figure className="image is-64x64">
                <Img
                  fixed={post.author.profileImage.fixed}
                  alt={`${post.author.name} Profile Image`}
                />
              </figure>
            </div>
            <div className="media-content">
              <div className="content">
                <p>
                  <strong>{post.author.name}</strong>{' '}
                  <Link to={`/author/${post.author.slug}`}>
                    <small>@{post.author.slug}</small>
                  </Link>
                </p>
                <div className="content has-text-weight-light">
                  {ReactHtmlParser(
                    post.author.bioNode.childMarkdownRemark.html
                  )}
                </div>
              </div>
            </div>
          </article>
        </div>
      </section>
    </div>
  </Layout>
)

BlogPostTemplate.propTypes = {
  data: PropTypes.object.isRequired
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    datoCmsPost(slug: { eq: $slug }) {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      title
      featuredImage {
        fixed(
          width: 1920
          height: 600
          imgixParams: {
            h: "800"
            auto: "compress"
            fm: "pjpg"
            fit: "crop"
            crop: "focalpoint"
            bri: -10
          }
        ) {
          src
        }
      }
      publishedDate
      author {
        id
        name
        slug
        bioNode {
          childMarkdownRemark {
            html
          }
        }
        profileImage {
          fixed(
            width: 90
            height: 90
            imgixParams: {
              fm: "png"
              auto: "compress"
              h: "90"
              crop: "faces"
              fit: "facearea"
              faceindex: 1
              facepad: 1
              mask: "ellipse"
            }
          ) {
            ...GatsbyDatoCmsFixed
          }
        }
      }
      textNode {
        childMarkdownRemark {
          html
          timeToRead
        }
      }
    }
  }
`
